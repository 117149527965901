.modal-profile{
    // border: 1px double pink;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;

    img{
        border-radius: 15px;
        object-fit: cover;
        object-position: center center;
    }

    h4{
        margin-top: 10px;
    }
}








#single-map-con {
    position: relative;
    width: 100%;
    height: 100%;
}

.modal-map {
    position: absolute;
    top: 10px;
    left: calc(100% + 80px);

    width: 35vw;
    height: 85vh;
    padding: 8px;
    background-color: antiquewhite;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    gap: 8px;

    transition: opacity 500ms linear;
    opacity: 0;

    img {
        // border: 2px solid red;
        height: 30%;
        width: 100%;
        border-radius: 5px;
        object-fit: cover;
    }
}

.modal-map.is-active {
    opacity: 1;
}