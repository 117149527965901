.post-container{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: aliceblue;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    /* column-gap: 5px;
    row-gap: 5px; */
}

.one-post-contain{
    position: relative;
    /* border: 1px solid pink; */
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    padding: 15px 20px;

    min-width: 0;
    min-height: 0;
}

.one-post{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 30px;

    border: 1px solid rgb(129, 76, 7);
    border-top: 4px solid rgb(129, 76, 7);
    border-radius: 1px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-sizing: border-box;

    background-color: antiquewhite;
    overflow: scroll;
    cursor: pointer;

    box-shadow: 0px 2px 10px -2px;
    transition: box-shadow 80ms linear;
}
.one-post:hover{
    box-shadow: 0px 2px 5px -2px;
}
.one-post-body{
    /* border: 1px solid pink; */
    height: 100%;
}
.one-post-body2{
    padding: 10% 0px;
    height: 60%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    overflow-y: scroll;
}
.one-post-body2 ~ p{
    /* font-family: "Arial Black", Gadget, sans-serif; */
    font-family: "Comic Sans MS", cursive, sans-serif;
    letter-spacing: 1px;
    word-spacing: 5px;
    text-transform: capitalize;
    color: grey;
}

.one-post-title{

    display: flex;
    align-items: center;
    justify-content: center;
    height: 25%;
    border-bottom: 1px solid rgb(195, 195, 195);
    white-space: nowrap;
    overflow-x: visible;
}
.one-post-title h4{
    font-family: 'Arial';
    font-size: 19px;
    font-weight: bold;
    margin: 0;
}

.one-post-line{
    --lh: 25px;
    height: 25px;
    flex-shrink: 0;
    /* border: 1px solid burlywood; */
    display: flex;
    align-items: center;
    overflow-y: hidden;
    margin-bottom: 7px;
}


.one-post-line div{
    flex-shrink: 0;
    width: var(--lh);
    height: var(--lh);
    margin-right: 10px;
    /* border: 1px solid green; */

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.one-post-line span{
    white-space: nowrap;
    font-family: "Comic Sans MS", cursive, sans-serif;
    text-transform: capitalize;
    color: rgb(0, 119, 255);
}
