
// .multi-select .css-g1d714-ValueContainer {
//     flex-wrap: nowrap;
//     overflow: scroll;
//     // height: 90px;
// }
.multi-select > div:first-of-type > div:first-of-type{
    flex-wrap: nowrap;
    overflow: scroll;
    white-space: nowrap;

    div {
        flex-shrink: 0;
    }
}

.lo-contain div:first-child{
    background-image: var(--bg-image);
}

// .css-9gakcf-option {
//     background-color: none;
// }


.multi-select:nth-of-type(1) [id^="react-select-"][id$="-listbox"] .location-option {
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
    border: 2px ridge burlywood;

    background-size: cover;
    background-position: center 75%;
    // background-image: url(../img/lin.jpg);
    background-color: none;

    height: 90px;

    label {
        // align-self: flex-end;
        font-weight: bold;
        font-size: large;
        color: white;
        text-shadow: 1px 1px 2px black;
        z-index: 1;
    }

    input {
        z-index: 1;
    }

    img {
        z-index: 0;
        // width: 100%;
        position: absolute;
        border: 2px dashed;
        object-fit: cover;
        object-position: center center;
    }
}

.multi-select:nth-of-type(3) [id^="react-select-"][id$="-listbox"] .location-option {
    border: 2px ridge rgb(208, 248, 255);
}

#nav-map{
    // border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid white;

    font-size: 900;
    width: 90px;
    height: 45px;
    background-color: rgb(87, 163, 87);
    box-shadow: inset 0px 0px 15px 3px aliceblue;

    transition: background-color 300ms linear, transform 100ms ease-in-out;
    transform: scale(1);
    &:hover{
        // animation: mybounce 300ms cubic-bezier(0, 2, 1, -0.5);
        transform: scale(1.08);
    }
}

#nav-map.is-active{
    background-color: rgb(72, 135, 72);
    box-shadow: inset 0px 0px 5px 0px aliceblue;
}


// @keyframes mybounce {
//     from{
//         transform: scale(1);
//     }
//     to {
//         transform: scale(1);
//     }
// }