.pagination-main{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-left{
    
}

.page-right{

}

.page-input{
    text-align: center;
    line-height: 30px;
    font-size: large;
}

.page-number{
    font-size: larger;
}