#contain {
    height: 100%;
    width: 100%;
    background-color: bisque;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
}

#side-bar{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    min-height: 0;
    min-width: 0;
    /* border: 2px solid orange; */
    overflow: scroll;
}

#profile-contain {
    --icon-pic-size: 115px;
    --icon-name-height: 15px;
    /* border: 1px solid green; */
    width: 100%;
    flex-basis: calc(4*var(--icon-name-height) + var(--icon-pic-size));
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
#profile-icon{
    position: relative;
    flex-grow: 0;
    margin: auto;
    width: var(--icon-pic-size);
    height: var(--icon-pic-size);
}
#profile-icon img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20%;
}
#profile-icon h5{
    position: absolute;
    text-align: center;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Courier New',
    Courier,
    monospace;
    color: darkblue;
    font-weight: bold;
    white-space: nowrap;
}

#profile-icon img{
    box-shadow: 0 0 0 1px rgb(154, 89, 136);
}

.contact-line{
    height: 45px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.contact-line div{
    width: 28px;
    height: 45px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid */
}
.contact-line span{
    overflow: scroll;
}

#contact-info{
    margin-top: 20px;
    border: 1px solid purple;
    position: relative;
    width: 90%;
    border-radius: 13px;
    padding: 10px 0px;
    flex-basis: 145px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}


.gear-main {
    --gs: 35px;
    width: var(--gs);
    height: var(--gs);
    cursor: pointer;
    /* border-radius: 50%; */
    position: absolute;
    top: -20px;
    right: -15px;
    /* border: 2px solid pink; */
}

.gear-main:hover .spinning-gear-contain{
    animation: spin 3.5s linear infinite;
}

.gear-main:hover .spinning-gear {
    animation: spin 2s cubic-bezier(0.075, 0.82, 0.165, 1) forwards,
    popup 0.7s linear forwards;
}

.spinning-gear-contain{
    width: 100%;
    height: 100%;
    /* border: 1px solid blue; */
}
.spinning-gear{
    width: calc(var(--gs) * 0.7);
    height: calc(var(--gs) * 0.7);
    /* background-color: orange; */
    color: purple;
    position: absolute;
    left: 0;
    bottom: 0;

}

@keyframes spin {
    from{
        transform: rotate(0deg);
    }
    to {
        transform: rotate(230deg);
    }
}

@keyframes popup {
    50%{
        width: 100%;
        height: 100%;
    }
    to{
        width: 100%;
        height: 100%;
    }
}

.side-button{
    position: relative;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;

    border: 1px solid rgb(0, 128, 193);
    border-radius: 5px;
    transition: all 90ms ease-out;
    box-shadow: 0 0 5px 2px;

    transition: background-color 200ms ease;
}

.side-button:hover{
    transform: scale(0.97);
    box-shadow: 0 0 3px 1px;
}
/* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;  really nice font*/
.side-button span{
    user-select: none;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 200;
}

.side-button:hover .toggle-switch{
    border-width: 1px;
}

.toggle-switch{
    position: absolute;
    left: 50%;
    top: 0;
    background-color: aliceblue;
    transform: translateX(-50%) translateY(-50%);
    --switch-height: 23px;
    --switch-width: 50px;
    height: var(--switch-height);
    width: var(--switch-width);
    border-radius: var(--switch-height);
    border: 2px solid darkslateblue;
    display: flex;
    align-items: center;
}
.side-button.is-active{
    background-color: lightskyblue;
}

.toggle-switch-circle{
    position: absolute;
    left: calc(var(--switch-height)*0.1);
    height: calc(var(--switch-height)*0.75);
    width: calc(var(--switch-height)*0.75);
    --circle-color: blueviolet;
    background-color: var(--circle-color);
    border-radius: 50%;
    transition: all 200ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggle-switch-circle.is-active{
    left: calc(var(--switch-width) - var(--switch-height))
    /* right: calc(var(--switch-height)*0.1); */
}

.toggle-switch-check{
    font-size: calc(var(--switch-height)*0.4);
    color: var(--circle-color);
    transition: color 200ms ease;
}
.toggle-switch-check.is-active{
    color: white;
}

#create-post{
    position: relative;
    width: 80%;
    height: 100px;
    flex-grow: 0.5;
    flex-basis: 110px;
    flex-shrink: 0;
    border: 1px solid orange;
    margin-top: 20px;
    text-align: center;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    /* align-items: center; */
    transition: background-color 100ms ease , box-shadow 150ms linear ;
    box-shadow: -1px 1px 20px -5px;
}
#create-post:hover{
    /* border-width: 2px; */
    background-color: rgb(127, 223, 255);
    box-shadow: -1px 1px 13px -5px;
}

#create-post:hover #create-post-button{
    background-color: rgb(206, 212, 217);
}

#create-post span{
    position: absolute;
    user-select: none;
    font-family: Cambria, Cochin, Georgia, Times, serif;
    font-weight: bold;
    font-size: 1.3rem;
    white-space: nowrap;
    top: 11%;
}
#create-post-button{
    cursor: pointer;
    position: absolute;
    bottom: 11%;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 1px solid pink;

    display: flex;
    justify-content: center;
    background-color: aliceblue;
}

#create-post-cross{
    transform: rotate(45deg) scale(1.1);
    color: orange;
    position: absolute;
    bottom: 20%;
    transition: all 100ms ease-in-out;
}
#create-post:hover #create-post-cross{
    bottom: 50%;
    transform: translateY(65%) scale(1.5) rotate(calc(45deg + 90deg));
    color: gray;
}


#log-out-button{
    cursor: pointer;
    margin: 20px 0;
    width: 100px;
    height: 33px;
    background-color: aqua;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: box-shadow 300ms cubic-bezier(0, 1.74, .85, 1.08);
    box-shadow: 0 0 4px 2px;
}
#log-out-button:hover{
    box-shadow: 0 0 10px 4px;
}

#log-out-button span{
    user-select: none;
    font-family: Cambria,Cochin,Georgia,Times,serif;
    font-weight: 900;
    color: blue;
}

.test-div{
    width: 70px;
    height: 100px;
}
.test-div:hover > button{
    background-color: green;
}

.search-single{
    width: 100%;
    margin-bottom: 15px;
}