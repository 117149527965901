
.topnav-main{
    height: 100%;
    width: 100vw;
    background-color: rgb(51, 51, 51);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.multi-select{
    outline: none;
    width: 35%;
    flex-wrap: nowrap;
}

#react-select-2-listbox{
    margin-top: 5px;
}


